<template>
  <div>
    <el-card style="margin-top: -10px">
      <div class="boxtop">
        <div class="box-card">
          <div class="cardtop">
            <p >账户总余额</p>
          </div>
          <div class="boxCenter">
            <p>余额</p>
            <p>￥{{datanum.totalAccountBalance}}</p>
          </div>
        </div>

        <div class="box-card" v-bind:class="{card3:datanum.accountState!=1}">
          <div class="cardtop">
             <p >货款账户
               <span class="state">{{datanum.accountState==1?"正常":"冻结"}}</span>
             </p>
          </div>
          <div class="boxCenter">
            <p>余额</p>
            <p>￥{{datanum.paymentAccountBalance}}</p>
          </div>
          <div class="cardtopp">
            <div>账号 {{datanum.paymentAccountNo}}</div>
            <div>有效日期至 {{datanum.effectiveDate}}</div>
          </div>
        </div>
        <div class="box-card " v-bind:class="{card3:datanum.accountState!=1}">
          <div class="cardtop">
             <p >服务费账户
                <span class="state">{{datanum.accountState==1?"正常":"冻结"}}</span>
             </p>
          </div>
          <div class="boxCenter">
            <p>余额</p>
            <p>￥{{datanum.serviceAccountBalance}}</p>
          </div>
           <div class="cardtopp">
            <div>账号 {{datanum.serviceAccountNo}}</div>
            <div>有效日期至 {{datanum.effectiveDate}} </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card style="margin-top: 10px">
      <template>
      <div class="line">
        <el-select v-model="transactionTimeType" placeholder="近一年" style="width:140px" @change="changeType">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
          <p> 总充值<span>{{LineAmount.totalRechargeAmount}}</span> </p>
          <p> 总结算<span>{{LineAmount.totalSettlementAmount}}</span></p>
          <p> 总服务费扣除  <span>{{LineAmount.totalDeductionServiceAmount}}</span></p>
       </div>
   </template>
   <template>
    <el-table
       v-loading="load"
      :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      :data="tableData"
      style="width: 100%;margin-top: 10px">
      <el-table-column
        header-align="center"
        align="center"
        prop="serialNumber"
        label="交易流水号"
        >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="accountName"
        label="交易账户"
        >
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="revenueExpenditureTypeName"
        width="130"
        label="收支类型">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="transactionTypeName"
        width="130"
        label="交易类型">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="transactionTime"
        label="交易时间">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="transactionAmount"
        label="交易金额">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="targetAccount"
        label="对方账号">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="statusText"
        width="130"
        label="交易状态">
      </el-table-column>
    </el-table>
     <el-pagination
            background
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="currPage"
            :page-sizes="[10, 20,30, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            class="text_center"
            layout="total,  prev, pager, next, sizes,jumper"
             style="text-align:right;margin-top:30px"
          ></el-pagination>
  </template>
    </el-card>
  </div>
</template>

<script>
import {dateFormat} from "@/utils/validate"
import {merchantCapitalAccountInfo,Records,merchantSummaryInfo} from  "@/api/captalInterface";
export default {
  data() {
    return {
      load:false,
      datanum:{},
      LineAmount:{},
       options: [{
          value: '1',
          label: '近一年'
        }, {
          value: '2',
          label: '近一个月'
        }, {
          value: '3',
          label: '近三个月'
        }, {
          value: '4',
          label: '近半年'
        }],
        transactionTimeType:"1",
       tableData: [
         {
            totalRechargeAmount:"",
            totalSettlementAmount: '',
            totalDeductionServiceAmount: '',
          }, 
          ],
          pageSize: 10,
          totalCount: 0,//数据长度
          currPage: 1,
    };
  },
  created(){
    this.getnum()
    this.getDataList()  
  },
  methods: {
    // 获取商户资金账户信息
    getnum(){
      merchantCapitalAccountInfo({}).then((data=>{
        if (data.code==200) {
          this.datanum=data.data
          this.datanum.effectiveDate= dateFormat(this.datanum.effectiveDate,"day")
        }
      }))
      let info={
        "transactionTimeType": this.transactionTimeType,
        "current": this.currPage,
        "size": this.pageSize,
      }
      merchantSummaryInfo(info).then((data=>{
        if (data.code==200) {
          this.LineAmount=data.data
        }
      }))
    },
    
    // 列表
    getDataList(){
       this.load=true
      Records({
        "transactionTimeType": this.transactionTimeType,
        "current": this.currPage,
        "size": this.pageSize,
       }).then((data=>{
           this.load=false
          this.tableData=data.data.records
          this.totalCount=data.data.total
      }))
    },
     // 每也数
      sizeChangeHandle(val) {
        this.pageSize = val;
        this.currPage = 1;
        this.getDataList();
      },
      // 当前页
      currentChangeHandle(val) {
        this.currPage = val;
        this.getDataList();
      },
      changeType(){
        this.getDataList()
      }
  },
};
</script>

<style  scoped>
.boxtop {
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: row nowrap;
}
.box-card {
  height: 252px;
  width: 420px;
  color: #ffff;
  position: relative;
  background-image: url("~pub/img/bj1.png");
  background-size: cover;
}
.card3 {
  background-image: url("~pub/img/bj2.png");
}
.cardtop {
  height: 50px;
  margin-top: 30px;
  border: 1px solid #aaa9b3;
  text-indent:30px;
  font-size: 16px;
}

.icon {
  position: absolute;
  top: -0px;
  left: 48%;
  font-size: 30px;
}
.boxCenter p{
  text-align: center;
   font-size: 16px;;
}
.state{
  font-size: 12px;
  margin-left: 10px;
}
.cardtopp{
  color: #b3b2aa;
  text-indent:30px;
  margin-top: 30px;
  font-size: 14px;
  line-height: 30px;
  /* background: darkcyan; */
}
 .line>>>.el-input__inner {
    border: 0;
  }
.line p{
  display: inline-block;
  font-size: 14px;
  margin-left: 30px;
  }
  .line span{
  margin-left: 10px;
  }
</style>


